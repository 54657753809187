import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import store from "@/store";

const helperExcel = {
  async excelImport(file, rowsToIgnore) {
    const types = file.name.split(".")[1];
    const fileType = ["xlsx", "csv"].some((item) => item === types);
    if (!fileType) {
      throw "¡Error de formato! Vuelva a seleccionar";
    }

    const workbook = new ExcelJS.Workbook();
    const buffer = await file.arrayBuffer();
    await workbook.xlsx.load(buffer);

    const result = [];

    workbook.eachSheet((sheet) => {
      const rows = [];
      sheet.eachRow((row, rowIndex) => {
        if (rowIndex > rowsToIgnore) {
          rows.push(row.values.slice(1)); // slice(1) para quitar el índice de la fila
        }
      });
      result.push({
        hoja: sheet.name,
        filas: rows,
      });
    });

    return result;
  },

  async excelExport(jsonArray, fileName) {
    if (!Array.isArray(jsonArray.filas) || jsonArray.filas.length === 0) {
      console.error(
        "Los datos proporcionados para la exportación están vacíos o no son válidos."
      );
      return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(jsonArray.hoja);

    const headers = Object.keys(jsonArray.filas[0]);
    worksheet.columns = headers.map((header) => ({
      header: header,
      key: header,
      width: header.length, 
    }));

    jsonArray.filas.forEach((row) => {
      worksheet.addRow(row);
    });

    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value ? cell.value.toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength;
    });

    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = {
          wrapText: true,
          vertical: "top",
          horizontal: "left",
        };
      });
    });

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("Error al escribir el archivo Excel:", error);
    }
  },

  translateDate(date) {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return date;

    const day = String(parsedDate.getUTCDate()).padStart(2, "0");
    const month = String(parsedDate.getUTCMonth() + 1).padStart(2, "0");
    const year = parsedDate.getUTCFullYear();

    return `${day}/${month}/${year}`;
  },

  async validarCabecerasExcel(file, expectedHeaders) {
    const workbook = new ExcelJS.Workbook();
    const buffer = await file.arrayBuffer();
    await workbook.xlsx.load(buffer);

    const sheet = workbook.getWorksheet(1); // Primera hoja
    const headers = sheet.getRow(1).values.slice(1); // Encabezados desde la fila 1

    if (headers.length < expectedHeaders.length) {
      store.commit(
        "user/setAlertMessage",
        {
          type: "warning",
          message: "La cantidad de cabeceras es menor a la esperada",
        },
        { root: true }
      );
      return false;
    }

    if (!expectedHeaders.every((header) => headers.includes(header))) {
      store.commit(
        "user/setAlertMessage",
        {
          type: "warning",
          message:
            "Formato de cabeceras inválido, consulte el formato ejemplo.",
        },
        { root: true }
      );
      return false;
    }

    return true;
  },
};

export default helperExcel;
