<template>
  <v-card>
    <v-container class="pa-0">
      <v-card-title class="text-button text-center">Enviar receta</v-card-title>
      <v-card-text class="text-center">
        <v-row>
          <v-col cols="12" class="py-0 mt-2">
            <v-btn
              class="elevation-2 my-1"
              append-icon="mdi-whatsapp"
              color="green"
              id="btn-whatsapp"
              @click="sendTo(1)"
            >
              Enviar por WhatsApp
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-btn
              class="elevation-2 my-1"
              append-icon="mdi-file-pdf-box"
              color="red"
              id="btn-pdf"
              @click="sendTo(2)"
            >
              Pdf
            </v-btn>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-btn
              class="elevation-2 my-1"
              append-icon="mdi-email"
              color="primary"
              id="btn-mail"
              :loading="loadingSendEmailBtn"
              @click="sendTo(3)"
            >
              Enviar por mail
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          variant="text"
          color="primary"
          @click="closeModal(true)"
          id="btn-close"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SendRecipe",
  props: {
    link: { type: String, required: true },
    telefono: { type: String, required: true },
    email: { type: String, required: true },
    paciente: { type: String, required: true },
  },
  data() {
    return {
      loadingSendEmailBtn: false,
    };
  },
  methods: {
    ...mapActions({
      sendRecipeByEmail: "solicitud/sendRecipeByEmail",
      setAlert: "user/setAlert",
    }),
    async sendTo(to) {
      switch (to) {
        case 1: // Wpp
          try {
            const mensaje = `Su receta electrónica ha sido emitida y está disponible para su consulta. Puede acceder a su receta a través del siguiente enlace:

${this.link}`;

            const whatsappUrl = `https://api.whatsapp.com/send?phone=${
              this.telefono
            }&text=${encodeURIComponent(mensaje)}`;
            window.open(whatsappUrl, "_blank");
          } catch (error) {
            console.error(error);
          }
          break;
        case 2: // Pdf
          window.open(this.link, "_blank");
          break;
        case 3: // Mail
          this.loadingSendEmailBtn = true;
          try {
            const bodyToSend = `
          <!DOCTYPE html>
                                <html>
                                <head>
                                    <style>
                                        body {
                                            font-family: Arial, sans-serif;
                                        }
                                        .container {
                                            max-width: 600px;
                                            margin: auto;
                                            padding: 20px;
                                            border: 1px solid #dcdcdc;
                                            border-radius: 5px;
                                        }
                                        .header {
                                            background-color: #4CAF50;
                                            color: white;
                                            padding: 10px;
                                            text-align: center;
                                        }
                                        .content {
                                            margin-top: 20px;
                                        }
                                        .content p {
                                            line-height: 1.6;
                                        }
                                        .footer {
                                            margin-top: 20px;
                                            font-size: 0.9em;
                                            color: #888888;
                                        }
                                        .link {
                                            color: #0066cc;
                                            text-decoration: none;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div class='container'>
                                        <div class='header'>
                                            <h1>¡Saludos ${this.paciente}!</h1>
                                        </div>
                                        <div class='content'>
                                            <p>Su receta electrónica ha sido proporcionada por su médico.</p>
                                            <p>Puede acceder a su receta a través del siguiente enlace:</p>
                                            <p><a href='${this.link}' class='link'>${this.link}</a></p>
                                        </div>
                                        <div class='footer'>
                                            <p>Este es un mensaje automático, por favor no responder.</p>
                                        </div>
                                    </div>
                                </body>
                                </html>
                            `;
            const data = {
              toEmail: this.email,
              subject: "Nueva receta",
              body: bodyToSend,
            };
            const response = await this.sendRecipeByEmail(data);
            if (response.status === 200) {
              this.setAlert({
                type: "success",
                message: "Receta enviada al mail ingresado del afiliado.",
              });
            }
          } catch (error) {
            console.error(error);
          }
          this.loadingSendEmailBtn = false;
          break;
        default:
          break;
      }
    },
    closeModal(reload) {
      this.$emit("closeModal", reload);
    },
  },
};
</script>

<style></style>
