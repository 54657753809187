<template>
  <v-container>
    <PageHeader :title="pageTitle" :path="goToPath" />
    <v-card class="elevation-1">
      <v-card-text class="py-0 my-0">
        <v-row>
          <v-col cols="12" class="mx-0 px-0">
            <v-data-table
              :headers="headers"
              :items="financiadores"
              class="pa-3"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              :search="search"
              :loading="isLoadingTable"
            >
              <template v-slot:top>
                <v-toolbar flat style="background-color: transparent">
                  <v-row>
                    <v-col cols="8" align-self="center" class="buscador pl-0">
                      <v-text-field
                        v-show="financiadores?.length > 0"
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        variant="underlined"
                        hide-details
                        clearable
                        class="ml-5"
                        style="width: auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      class="d-flex align-center"
                      :class="isMobile ? 'mt-3' : ''"
                      style="justify-content: end"
                      cols="4"
                      v-if="permisosFinanciadores.canCreate"
                    >
                      <v-btn
                        color="primary"
                        variant="flat"
                        @click="openModalEdit()"
                      >
                        Nuevo
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip
                  text="Gestión de farmacias"
                  location="left"
                  v-if="permisosFinanciadores.canGoToFarmacias"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      v-bind="props"
                      @click="goToGestionFarmacias(item.financiadorId)"
                      id="v-icon-gestion-farmacias"
                    >
                      {{ drugstoreIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  text="Gestión de usuarios emisores"
                  location="left"
                  v-if="permisosFinanciadores.canGoToUsuariosEmisores"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      v-bind="props"
                      @click="goToGestionUsuariosEmisores(item.financiadorId)"
                      id="v-icon-gestion-usuarios-emisores"
                    >
                      {{ detailsUserIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  text="Gestionar entidades emisoras"
                  location="left"
                  v-if="permisosFinanciadores.canGoToEntidadesEmisores"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      v-bind="props"
                      @click="openEmtidadesEmisorasDialog(item)"
                      id="v-icon-entidades-emisores"
                    >
                      {{ "mdi-hospital-building" }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  text="Ver tipos prestadores"
                  location="left"
                  v-if="permisosFinanciadores.canVerTiposPrestadores"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      size="20"
                      v-bind="props"
                      @click="openVerTipoPrestadoresDialog(item)"
                      id="v-icon-ver-prestadores"
                    >
                      {{ accountBoxMultipleIcon }}
                    </v-icon>
                  </template>
                </v-tooltip>
                <v-tooltip
                  location="left"
                  v-if="permisosFinanciadores.canSeeReport"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="openReporteDialog(item)"
                    >
                      {{ "mdi-text-box-search" }}
                    </v-icon>
                  </template>
                  <span>Ver reportes financiador</span>
                </v-tooltip>
                <v-tooltip location="left" v-if="permisosFinanciadores.canEdit">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="openModalEdit(item)"
                    >
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <span>Editar financiador</span>
                </v-tooltip>
                <v-tooltip
                  location="left"
                  v-if="permisosFinanciadores.canDelete"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      v-bind="props"
                      size="20"
                      @click="openDeleteDialog(item.financiadorId)"
                    >
                      {{ deleteIcon }}
                    </v-icon>
                  </template>
                  <span>Eliminar financiador</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
  <v-dialog
    :max-width="isMobile ? '95%' : '70%'"
    v-model="dialogVerTipoPrestadores"
    persistent
  >
    <VerTiposPrestadores
      :financiadorItem="financiadorItem"
      @cerrarVerTipoPrestadoresDialog="closeVerTipoPrestadoresDialog"
      @reloadFinanciadores="loadFinanciadores"
    />
  </v-dialog>
  <v-dialog
    v-if="isOpenModalEdit"
    v-model="isOpenModalEdit"
    :max-width="isMobile ? '95%' : '60%'"
    persistent
  >
    <EditFinanciadores
      :financiadorItem="financiadorItem"
      @closeAndReload="closeAndReload"
    />
  </v-dialog>
  <v-dialog
    v-if="isOpenModalReport"
    v-model="isOpenModalReport"
    :max-width="isMobile ? '95%' : '45%'"
    persistent
  >
    <ReportFinanciadores
      :financiadorProp="financiadorItem"
      @closeDialog="closeAndReload"
    />
  </v-dialog>
  <v-dialog
    :max-width="isMobile ? '95%' : '50%'"
    v-model="dialogDelete"
    persistent
  >
    <DeleteDialog
      v-if="dialogDelete"
      title="financiador"
      :subtitle="deleteSubtitle"
      :isLoading="isLoadingDelete"
      @eliminar-confirmado="confirmDelete"
      @eliminar-cancelado="closeDeleteDialog"
    />
  </v-dialog>
</template>

<script>
import EditFinanciadores from "@/components/modules/financiadores/EditFinanciadores.vue";
import ReportFinanciadores from "@/components/modules/financiadores/ReportFinanciadores.vue";
import VerTiposPrestadores from "@/components/modules/financiadores/VerTiposPrestadores.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layout/PageHeader.vue";
import DeleteDialog from "@/components/shared/DeleteDialog.vue";

export default {
  name: "FinanciadoresView",
  components: {
    EditFinanciadores,
    VerTiposPrestadores,
    PageHeader,
    DeleteDialog,
    ReportFinanciadores,
  },
  data() {
    return {
      deleteSubtitle: enums.messages.FINANCIADORES_DELETE_SUBTITLE,
      isOpenModalEdit: false,
      isOpenModalReport: false,
      dialogVerTipoPrestadores: false,
      dialogDelete: false,
      search: "",
      financiadorItem: null,
      pageTitle: enums.permises.FINANCIADORES.name,
      goToPath: enums.permises.HOME.path,
      searchIcon: enums.icons.SEARCH_ICON,
      accountBoxMultipleIcon: enums.icons.ACCOUNT_BOX_MULTIPLE,
      drugstoreIcon: enums.icons.DRUGSTORE_ICON,
      detailsUserIcon: enums.icons.DETAILS_USER,
      editIcon: enums.icons.EDIT_ICON,
      deleteIcon: enums.icons.DELETE_ICON,
      financiadorToDelete: null,
      isLoadingTable: false,
      isLoadingDelete: false,
      headers: [
        {
          title: "Número",
          key: "financiadorId",
          sortable: false,
        },
        {
          title: "Nombre",
          key: "financiadorNom",
          sortable: false,
        },
        {
          title: "Código",
          key: "financiadorCodigo",
          sortable: false,
        },
        {
          title: "URL",
          key: "financiadorUrl",
          sortable: false,
        },
        {
          title: "Acciones",
          key: "actions",
          sortable: false,
          align: "end",
        },
      ],
      financiadores: [],
    };
  },
  computed: {
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
    }),
    permisosFinanciadores() {
      let canCreate = false;
      let canEdit = false;
      let canDelete = false;
      let canGoToUsuariosEmisores = false;
      let canGoToFarmacias = false;
      let canGoToEntidadesEmisores = false;
      let canVerTiposPrestadores = false;
      let canSeeReport = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.CREAR_FINANCIADOR.code:
              canCreate = true;
              break;
            case enums.permises.EDITAR_FINANCIADOR.code:
              canEdit = true;
              break;
            case enums.permises.ELIMINAR_FINANCIADOR.code:
              canDelete = true;
              break;
            case enums.permises.VER_TIPOS_PRESTADORES.code:
              canVerTiposPrestadores = true;
              break;
            case enums.permises.GESTION_USUARIOS_EMISORES.code:
              canGoToUsuariosEmisores = true;
              break;
            case enums.permises.EMISORES.code:
              canGoToEntidadesEmisores = true;
              break;
            case enums.permises.VER_REPORTES_CONFIGURACION.code:
              canSeeReport = true;
              break;
            case enums.permises.GESTION_FARMACIAS.code:
              canGoToFarmacias = true;
              break;
            default:
              break;
          }
        });
      }
      return {
        canCreate,
        canEdit,
        canDelete,
        canVerTiposPrestadores,
        canGoToUsuariosEmisores,
        canGoToEntidadesEmisores,
        canSeeReport,
        canGoToFarmacias,
      };
    },
  },
  created() {
    this.updateFinanciadorId(null); // Updeteo el financiadorId en el state para limpiar el dato y que no quede dando vueltas
    this.loadFinanciadores();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },
  methods: {
    ...mapActions({
      updateFinanciadorId: "financiador/updateFinanciadorId",
      getFinanciadores: "financiador/getFinanciadores",
      deleteFinanciador: "financiador/deleteFinanciador",
      setAlert: "user/setAlert",
    }),

    async loadFinanciadores() {
      this.isLoadingTable = true;
      const response = await this.getFinanciadores();
      this.financiadores = response;
      this.isLoadingTable = false;
    },
    async openReporteDialog(financiadorId) {
      this.isOpenModalReport = true;
      this.financiadorItem = financiadorId;
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.financiadorItem = item;
    },
    openVerTipoPrestadoresDialog(item) {
      this.dialogVerTipoPrestadores = true;
      this.financiadorItem = item;
    },
    closeVerTipoPrestadoresDialog() {
      this.dialogVerTipoPrestadores = false;
      this.loadFinanciadores();
    },
    closeAndReload(reload) {
      this.isOpenModalReport = this.isOpenModalEdit = false;
      if (reload) {
        this.loadFinanciadores();
      }
    },
    async confirmDelete() {
      this.isLoadingDelete = true;
      try {
        const response = await this.deleteFinanciador(this.financiadorToDelete);
        if (response?.status === 200) {
          this.setAlert({ type: "success", message: "Eliminado con éxito" });
          this.dialogDelete = false;
          this.loadFinanciadores();
        }
      } catch (error) {
        console.error(error);
      }
      this.isLoadingDelete = false;
    },
    openDeleteDialog(id) {
      this.dialogDelete = true;
      this.financiadorToDelete = id;
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
    },
    openEmtidadesEmisorasDialog(item) {
      this.updateFinanciadorId(item.financiadorId);
      this.$router.push({
        name: "Entidades Emisoras",
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
    },
    goToGestionUsuariosEmisores(id) {
      this.updateFinanciadorId(id);
      this.$router.push({
        name: "GestionUsuariosEmisores",
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
    },
    goToGestionFarmacias(id) {
      this.updateFinanciadorId(id);
      this.$router.push({
        name: "GestionFarmacias",
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .buscador {
    width: 70%;
    flex: none;
  }
}
.no-upper-case {
  text-transform: none;
}
</style>
