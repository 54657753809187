import axios from "@/axios/axios-api";

export default {
  async getUsuariosEmisoresByFinanciadorId(_, payload) {
    try {
      const response = await axios.get(
        `api/usuariosEmisores/usuarios-emisores/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al traer usuarios emisores", error);
    }
  },
  async getUsuarioEmisorByFinanciadorId(_, payload) {
    try {
      const response = await axios.get(
        `api/usuariosEmisores/usuario-emisor/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al traer usuarios emisores", error);
    }
  },
  async searchPrestadorByCuit(_, payload) {
    try {
      let url = `api/usuariosEmisores/search-prestador?cuit=${payload.cuit}&financiadorId=${payload.financiadorId}`;

      if (payload.preId != null) {
        url += `&preId=${payload.preId}`;
      }

      const response = await axios.get(url);
      return response;

    } catch (error) {
      console.error("Error al traer usuarios emisores", error);
    }
  },
  async saveUsuarioEmisor(_, payload) {
    try {
      const response = await axios.post(
        "api/usuariosEmisores/usuarios-emisores",
        payload
      );
      return response;
    } catch (error) {
      console.error("Error al guardar cambios financiador", error);
    }
  },
  async deleteUsuarioEmisor(_, payload) {
    try {
      const response = await axios.delete(
        `api/usuariosEmisores/usuarios-emisores?preId=${payload.preId}&preXFinId=${payload.preXFinId}&usuarioId=${payload.usuarioId}`
      );

      if (response.status == 204) return null;
      else return response;
    } catch (error) {
      console.error("Error al eliminar usuarios emisores", error);
    }
  },
  async changeUserEnabledStatus(_, payload) {
    try {
      const response = await axios.get(
        `api/usuariosEmisores/change-user-status/${payload}`
      );
      return response?.data;
    } catch (error) {
      console.error("Error al cambiar el estado del usuario", error);
    }
  },
};
