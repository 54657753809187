const icons = Object.freeze({
  SNB_SUCCESS: "mdi-check-circle",
  SNB_ERROR: "mdi-alert-octagon-outline",
  SNB_INFO: "mdi-information-outline",
  SNB_WARNING: "mdi-alert-outline",
  OPEN_EYE_ICON: "mdi-eye",
  CLOSE_EYE_ICON: "mdi-eye-off",
  HOME_ICON: "mdi-home",
  USER_ICON: "mdi-account-circle",
  SEARCH_ICON: "mdi-magnify",
  EDIT_ICON: "mdi-pencil",
  DELETE_ICON: "mdi-trash-can-outline",
  BOOK_ICON: "mdi-book-multiple",
  ACCOUNT_BOX_MULTIPLE: "mdi-account-box-multiple",
  BACK_ARROW_ICON: "mdi-arrow-left",
  CLEAR_FILTERS: "mdi-filter-remove-outline",
  CALENDAR_CLOCK: "mdi-calendar-clock",
  DOCUMENT: "mdi-file-document",
  CALENDAR: "mdi-calendar",
  RESTORE_ICON: "mdi-backup-restore",
  COPY_ICON: "mdi-content-copy",
  DOWN_ARROW_ICON: "mdi-chevron-down",
  UP_ARROW_ICON: "mdi-chevron-up",
  DOCUMENT_DELETE_ICON: "mdi-text-box-remove-outline",
  PRINT_ICON: "mdi-printer",
  DOCUMENT_EDIT_ICON: "mdi-file-document-edit-outline",
  CLOSE_ICON: "mdi-close",
  DOCTOR_ICON: "mdi-doctor",
  ADD_ICON: "mdi-plus",
  DETAILS_USER: "mdi-account-details",
  CHECK_ICON: "mdi-check-outline",
  CROSS_ICON: "mdi-close-outline",
  DISABLE_USER_ICON: "mdi-account-off",
  ENABLE_USER_ICON: "mdi-account-check",
  DRUGSTORE_ICON: "mdi-store-plus",
  QRCODE_REMOVE: "mdi-qrcode-remove",
  MICRO: "mdi-microphone",
  EDIT_CIRCLE: "mdi-pencil-circle",
  FORMAT_BOLD: "mdi-format-bold",
  FORMAT_ITALIC: "mdi-format-italic",
  FORMAT_UNDERLINE: "mdi-format-underline",
  FORMAT_STRIKE: "mdi-format-strikethrough-variant",
  FORMAT_CLEAR: "mdi-format-clear",
  SEE: "mdi-eye",
  UNDO: "mdi-undo",
  REDO: "mdi-redo",
  ADD_IMAGE: "mdi-image-plus",
  OL: "mdi-format-list-numbered",
  UL: "mdi-format-list-bulleted",
  QUOTE: "mdi-format-quote-close",
  REMOVE: "mdi-minus",
  LIST_CHECKBOX: "mdi-format-list-checkbox",
  ALIGN_LEFT: "mdi-format-align-left",
  ALIGN_RIGHT: "mdi-format-align-right",
  ALIGN_CENTER: "mdi-format-align-center",
  ALIGN_JUSTIFY: "mdi-format-align-justify",
  TABLE_ADD: "mdi-table-plus",
  COLOR_TEXT: "mdi-format-color-text",
  COLOR_FILL: "mdi-format-color-fill",
  LINK: "mdi-link-variant",
  CHEVRON_RIGHT: "mdi-chevron-right",
  INDENT: "mdi-format-indent-increase",
  OUTDENT: "mdi-format-indent-decrease",
  LOCK_RESET: "mdi-lock-reset",
});

export default icons;
