<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ title }}</span>
    </v-card-title>
    <v-card-text class="text-left">
      {{ text }}
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="text"
        color="primary"
        @click="closeModal(false)"
        id="btn-close"
      >
        Cancelar
      </v-btn>
      <v-btn
        variant="flat"
        color="primary"
        @click="confirmResendPassword()"
        :loading="isLoading"
        id="btn-confirm"
        >Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReenviarContraseña",
  props: {
    userId: { type: Number, required: true },
  },
  data() {
    return {
      newPassword: [],
      isLoading: false,
      text: "Se enviará por email una nueva contraseña de acceso para el usuario.",
      title: "Reenviar contraseña",
    };
  },
  created() {
  },
  methods: {
    ...mapActions({
      resendPassword: "user/resendPassword",
      setAlert: "user/setAlert",
    }),
    async confirmResendPassword() {
      this.isLoading = true;
      try {
        const response = await this.resendPassword(this.userId);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Contraseña reenviada con éxito.",
          });
          this.closeModal(false);
        }
      } catch (error) {
        console.error("Error al reenviar contraseña.", error);
      }
      this.isLoading = false;
    },
    closeModal(reload) {
      this.$emit("closeDialog", reload);
    }
  },
};
</script>

<style></style>
