import axios from "axios";

export default {
  async getUsuarios(context, payload) {
    try {
      const response = await axios.post("api/usuarios/get-users", payload);
      return response?.data;
    } catch (error) {
      console.error("Error al cargar los usuarios", error);
    }
  },
  async postUsuario(context, payload) {
    try {
      const response = await axios.post("api/usuarios/save-user", payload);
      return response;
    } catch (error) {
      console.error("No se pudo guardar datos", error);
    }
  },
  async restorePassword(context, payload) {
    try {
      const response = await axios.post(
        `api/usuarios/restore-password/${payload}`
      );
      return response;
    } catch (error) {
      console.error("No se pudo restaurar la contraseña", error);
    }
  },
  async deleteUsuario(context, payload) {
    try {
      const response = await axios.delete(
        `api/usuarios/delete-user/${payload}`
      );
      return response;
    } catch (error) {
      console.error("No se pudo elimiar este usuario", error);
    }
  },
  async removeUserQRSecretKey(context, payload) {
    try {
      const response = await axios.post(
        `api/usuarios/remove-qr-key/${payload}`
      );
      return response.data;
    } catch (error) {
      console.error("No se pudo remover el QR del usuario", error);
    }
  },
  async resendPassword(context, payload) {
    try {
      const response = await axios.post(
        `api/usuarios/resend-password/${payload}`
      );
      return response;
    } catch (error) {
      console.error("No se pudo reenviar la contraseña del usuario", error);
    }
  },
  changeAppLoading({ commit }, val) {
    commit("setAppLoading", val);
  },
  setAlert(context, alert) {
    context.commit("setAlertMessage", alert);
  },
};
