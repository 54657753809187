import axios from "axios";

export default {
  async getPermisosByUserGroup(_, payload) {
    try {
      return (
        await axios.get(
          `/api/manualUsuario/get-permisos-by-user-group?grupoUsuId=${payload}`
        )
      )?.data;
    } catch (error) {
      console.error(`No se pudo traer los datos, ${error}`);
    }
  },
  async getManualUsuarioByGrupoAndPermiso(context, payload) {
    const response = await axios.get(
      `api/manualUsuario/get-manual-usuario?grupoUsuId=${payload.grupoUsuId}&permisoId=${payload.permisoId}`
    );
    return response;
  },
  async getManualUsuarioLogin(context, payload) {
    const response = await axios.get(
      `api/manualUsuario/get-manual-usuario-login?grupoUsuId=${payload?.grupoUsuId ?? -1}&permisoId=${payload?.permisoId ?? -1}`
    );
    return response;
  },
  async saveManualUsuario(context, payload) {
    const response = await axios.post(
      "api/manualUsuario/save-manual-usuario",
      payload
    );
    return response;
  },
};
